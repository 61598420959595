<template>
  <div style="min-height: 700px">
    <BreadCrumbs>خانه > پیام های من</BreadCrumbs>
    <v-container style="max-width: 664px;padding-top: 54px">
      <h1 class="mr-5 font-weight-regular" style="font-size: 24px;">پیام های من</h1>

      <div class="messages-content">

        <div v-for="(message,i) in messages" :key="i" :class="{'messages-item--background':i%2==0}" class="messages-item">

          <div v-if="message.seen!=1" class="messages-seen">
            <p>خوانده نشده</p>
          </div>

          <div class="d-flex align-center justify-space-between">
          <p class="primary--text mb-1 font-weight-bold">{{message.title}}</p>
          <p class="primary--text mb-1">{{message.time | momentHour}}</p>
          </div>

          <p class="mb-1" style="text-align: justify;text-justify: inter-word">{{message.text}}</p>

        </div>

        <p v-if="!ismessages" class="pt-5 text-center">پیامی جهت نمایش وجود ندارد.</p>
      </div>

      <div v-if="!unloaded" class="d-flex justify-center my-2">
        <div class="d-flex" style="width: 150px;">
<!--          <v-text-field
              label="صفحه"
              outlined
              :disabled="unloaded"
              hide-details
              v-model="pagetxt"
              @keypress="isNumber($event)"
              dense
              class="rounded-xl ml-1"
          ></v-text-field>
          <v-btn
              class="btn rounded-xl"
              height="40"
              @click="gotoPage()"
          >
            برو
          </v-btn>-->

        </div>
      </div>

      <div v-if="!unloaded" class="text-center mx-0 mb-4 pagintion">
        <v-pagination
            v-model="page"
            :length="count"
            :total-visible="10"

        ></v-pagination>
      </div>

    </v-container>

  </div>
</template>

<script>
import BreadCrumbs from "../components/BreadCrumbs";
import axios from "axios";
import moment from "jalali-moment";
export default {
  name: 'App',
  filters : {
    momentHour(date){
      let hours = moment.unix(date).locale('fa').startOf('hour').fromNow();
      return moment.unix(date).locale('fa').calendar({
        sameDay: hours,
        lastDay: '[دیروز] HH:mm',
        lastWeek: hours,
        sameElse: 'jYYYY/jMM/jDD'
      });
    },
  },
  components : {
    BreadCrumbs
  },
  data() {
    return {
      page : 1,
      messages : [],
      pagetxt : null,
      count : null,
      ismessages : true,
      unloaded : true,
    }
  },
  created() {
    this.fetchMessages();
  },
  metaInfo() {
    return {
      title:'پیام های من',
      link: [{rel: 'canonical', href: this.$store.state.url+'/messages'}]
    }
  },
  watch : {
    page(){
      this.fetchMessages();
      this.pagetxt = this.page;
    }
  },
  methods : {
    fetchMessages(){
      axios.post(this.$store.state.api + 'allmessages', {
        userId : this.$store.state.userId,
        page : this.page,
      }).then(response => {
        this.messages = response.data.data.data;
        if(this.messages.length===0){
          this.ismessages = false;
        } else {
          this.ismessages = true;
        }
        this.count = Math.ceil(parseInt(response.data.data.count)/10);
        this.unloaded = false;

      })
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) /*&& charCode !== 46*/) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    gotoPage(){
      this.page = parseInt(this.pagetxt);
    }
  }
};
</script>

<style lang="scss">
.messages-content{
  min-height: 64px;
  overflow: hidden;
  border-radius: 20px;
  background-color: #f1f1f1;
}

.messages-seen{
  position: absolute;
  width: 50px;
  transform: rotate(-90deg);
  font-size: 8px;
  padding-top: 2px;
  left: -15px;
  top: 30px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  color: white;
  background-color: #061157;
  height: 15px;
}

.messages-item {
  /*min-height: 110px;*/
  position: relative;
  padding: 8px 20px;
  font-size: 18px;
  color: #7e7e7e;
}

.messages-item--background{
  background-color: #e8e8e8;
}

.pagintion * {
  color: black !important;
  box-shadow: none !important;
  border-radius: 40% !important;
}

.pagintion .theme--light.v-pagination .v-pagination__item {
  background: #e5e5e5;

}

.pagintion .theme--light.v-pagination .v-pagination__item--active{
  background-color: #570654 !important;
  color: white !important;
}

.pagintion .theme--light.v-pagination .v-pagination__navigation {
  background: #e5e5e5 !important;
}
</style>
